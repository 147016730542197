import { Classes } from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import {
  HexButton,
  HexMenu,
  HexMenuItem,
  HexPopover,
} from "../../../../hex-components";
import { SingleChevronDownIcon, TickIcon } from "../../../icons/CustomIcons";
import { BaseInputConfiguration } from "../../../input/config/BaseInputConfiguration";
import { InputConfigurationComponent } from "../../../input/config/InputConfigurationComponent";

const StyledPopover = styled(HexPopover)`
  width: 100%;
`;

const DropdownOption = styled(HexButton)<{ $capitalize: boolean }>`
  justify-content: space-between;
  width: 100%;

  ${({ $capitalize }) => $capitalize && "text-transform: capitalize;"}

  outline: none;

  .${Classes.BUTTON_TEXT} {
    line-height: 15px;
  }
`;

const StyledMenu = styled(HexMenu)`
  min-width: 210px;
`;

const StyledMenuItem = styled(HexMenuItem)<{ $capitalize: boolean }>`
  ${({ $capitalize }) => $capitalize && "text-transform: capitalize;"}
`;

export const DropdownFormField: InputConfigurationComponent<string> =
  React.memo(function DropdownFormField({
    disabled,
    error,
    info,
    label,
    onChange,
    options,
    value,
  }) {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const changeCallback = useCallback((item) => onChange(item), [onChange]);

    const selectedOption = options?.find((option) => option.value === value);
    const selectedLabel = selectedOption?.label;

    const itemMenu = (
      <StyledMenu>
        {options?.map((item) => (
          <StyledMenuItem
            key={item.value}
            $capitalize={item.capitalize ?? true}
            labelElement={item.value === value ? <TickIcon /> : undefined}
            text={item.label}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => changeCallback(item.value)}
          />
        ))}
      </StyledMenu>
    );

    return (
      <BaseInputConfiguration
        disabled={disabled}
        error={error}
        info={info}
        label={label}
      >
        <StyledPopover
          content={itemMenu}
          isOpen={isMenuOpen}
          minimal={true}
          placement="bottom"
          // eslint-disable-next-line react/jsx-no-bind
          onClose={() => setIsMenuOpen(false)}
        >
          <DropdownOption
            $capitalize={selectedOption?.capitalize ?? true}
            active={isMenuOpen}
            className={Classes.INPUT}
            disabled={disabled}
            rightIcon={<SingleChevronDownIcon />}
            small={false}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {selectedLabel || "Select"}
          </DropdownOption>
        </StyledPopover>
      </BaseInputConfiguration>
    );
  });
